import React, { useContext } from 'react'
import Navbar from './navbar'
import Sidebard from './sidebar'
import Footer from "./footer";
import { AuthContext } from '../../Auth/context/Auth/AuthContext';
export default function Menus(user) {
  const { Role } = useContext(AuthContext)
  let visualizarSidebar = sessionStorage.getItem('tabs')
  if (Role != 'Simple User') {
    visualizarSidebar = true
  }
  const user_email = user
  return (
    <div>
      <Navbar />
      {visualizarSidebar && <Sidebard />}
      <Footer user_info={user_email.user} />
    </div>
  )
}