import React, { useEffect, useState } from 'react'

export const useAuth = () => {
  const [Auth, setAuth] = useState(false);
  const [User, setUser] = useState({});
  const [role, setRole] = useState([]);
  
    useEffect(() => {
      const storedUser = JSON.parse(sessionStorage.getItem('userData'));
      const auth = storedUser?.auth;
      const role = storedUser?.role;
      if (auth && storedUser) {
        setAuth(auth);
        setUser(storedUser);
        setRole(role)
      }
    }, []);

    const getRole = (role) => {
      setRole(role)
    }
  
    const signin = (userData) => {
      sessionStorage.setItem("userData", JSON.stringify(userData));
      setUser(userData);
      setAuth(true);
    };
  
    const logout = (e) => {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("dataFilter");
      sessionStorage.removeItem("tabs");
      setAuth(false);
    };
  return {
    Auth,
    User,
    role,
    getRole,
    signin,
    logout
  }
}

