import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { AuthContext } from '../../Auth/context/Auth/AuthContext';
import { ReactComponent as HomeIcon } from '../../assets/image/homeIcon.svg';
import { ReactComponent as Upload } from '../../assets/image/upload.svg';
import { ReactComponent as ButtonSide } from '../../assets/image/buttonSide.svg';
import { ReactComponent as ButtonHide } from '../../assets/image/buttonHide.svg';
import Logo from "../../assets/image/logo-white.png";
import LogoSymb from "../../assets/image/Logo.png";
import { initTE, Sidenav } from "tw-elements";

export default function Sidebar() {
    const { Role: role } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    let elements = [];

    if (role === 'Simple User') {
        elements = [
            { path: '/Admin', tooltipContent: 'Home', icon: <HomeIcon /> },
            { path: '/Plan', tooltipContent: 'Plan', icon: <Upload /> },
        ];
    } else {
        elements = [
            { path: '/Admin', tooltipContent: 'Home', icon: <HomeIcon /> },
            { path: '/Plan', tooltipContent: 'Plan', icon: <Upload /> },
        ];
    }

    useEffect(() => {
        initTE({ Sidenav });
        const currentPath = location.pathname;
        const currentIndex = elements.findIndex(element => element.path === currentPath);
        setActiveIndex(currentIndex);
    }, [location]);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='fixed left-0 w-full z-[8000]'>
            <nav style={{ width: isExpanded ? '250px' : '70px' }}
                id="sidenav-1"
                className={`fixed top-0 left-0 z-[1035] flex flex-col justify-between items-center h-full min-h-screen bg-nc-gray dark:bg-[white] shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] transition-all duration-300 ease-in-out`}>
                <div>
                    <NavLink to={role !== 'Simple User' ? '/Admin' : '/'}>
                        <div className="flex items-center justify-center w-full mt-4 mb-4 cursor-pointer">
                            <img src={isExpanded ? Logo : LogoSymb}
                                style={isExpanded ? { padding: '0 1.5em' } : {}}
                                className={`transition-all duration-300 ease-in-out ${isExpanded ? 'w-auto h-[50%]' : 'w-8'}`}
                                alt="Logo" />
                        </div>
                    </NavLink>
                    <ul className={`${isExpanded ? 'relative m-0 list-none w-full px-[0.2rem]' : 'relative m-0 list-none w-auto'}`}>
                        {elements.map((element, index) => (
                            <li key={index} className="relative m-auto lg:mb-0 w-full">
                                <Tooltip title={isExpanded ? '' : element.tooltipContent} placement="right">
                                    <NavLink to={element.path}
                                        className={`${activeIndex === index ? 'bg-[#662D91] text-white' : 'text-gray-300'} hover:text-white flex items-center h-12 cursor-pointer truncate rounded-[5px] px-6 py-4 transition duration-300 ease-linear hover:bg-slate-50 focus:outline-none active:text-inherit`}>
                                        {element.icon}
                                        {isExpanded && <span className="ml-4">{element.tooltipContent}</span>}
                                    </NavLink>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className="fixed left-0 ml-[6px] bottom-6 lg:bottom-14 z-[9999] flex justify-center items-center w-[36px] h-[36px] mt-0 rounded text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-pc-purple hover:shadow-lg focus:shadow-lg focus:outline-none active:bg-primary-800 active:shadow-lg"
                    onClick={handleExpand}>
                    {isExpanded ? <ButtonHide /> : <ButtonSide />}
                </button>
            </nav>
        </div>
    );
}
