import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../Auth/context/Auth/AuthContext";
import { Loading } from "../Components/Loading";
const ProtectedRoute = ({ isAllowed, children, redirecTo = "/" }) => {
  const { Auth, User } = useContext(AuthContext);
  const [isloading, setLoading] = useState(false);
  var roleUser = [];
  var validateRoleUser = false;

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 100);
  }, []);

  const SelectRoles = (condition) => {
    switch (condition) {
      case 'External':
        return ["Simple User"];
      case 'Internal':
        return ["Admin", "User Enovate", "Moderator"];
      case 'Bound':
        return ["Admin", "User Enovate", "Moderator","Simple User"];
      default:
        return 'Dont Rol';
    }
  };

  const Roles = SelectRoles(isAllowed);

  if (!isloading) {
    return <div><Loading/></div>;
  }
  
  if (roleUser !== null) {
    roleUser = User.role;
    if (!Array.isArray(roleUser)) {
      roleUser = [roleUser];
    }
    validateRoleUser = roleUser.every((element) => Roles.includes(element));
  }

  if (Auth && validateRoleUser) {
    return children ? children : <Outlet />;
  }

  if (Auth && !validateRoleUser) {
    alert("I don't have permission fot this view");
    if (
      roleUser.includes("Admin") ||
      roleUser.includes("User Enovate") ||
      roleUser.includes("Moderator")
    ) {
      return <Navigate to={"/Admin"} />;
    } else {
      return <Navigate to={"/"} />;
    }
  }
  if (!Auth) {
    alert("please login to continue");
    return <Navigate to={redirecTo} />;
  }
};

export default ProtectedRoute;
