import React, { useContext, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../Auth/context/Auth/AuthContext';
import { loginUser, verifyToken } from '../../Auth/services/loginAPI';
import Logo from '../../assets/image/logo-white.png'
import { Alert, Tooltip } from 'antd';
const initialUser = {
  user_name: "",
  user_password: "",
}
export const AuthForm = () => {
  const formRef = useRef();
  const { signin } = useContext(AuthContext);
  const [user_data, setUser] = useState(initialUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [captchaValide, changeCaptchaValide] = useState(null);
  const [userValide, changeUserValide] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const captcha = useRef(null);
  const { access_credentials } = useParams();
  const pathname = location.pathname.replace(`/${access_credentials}`, '');
  const infoUser = access_credentials ? access_credentials.substring(1) : "";
  const infoPathName = pathname ? pathname.substring(1) : "";
  const [messageAlert, setMessageAlert] = useState("");
  const [tokenCredential, setTokenCredential] = useState("");

  useEffect(() => {
    if (infoUser && infoPathName === "User-Credential-Access") {
      try {
        const decodeCredentials = infoUser !== null ? infoUser : "";
        if (decodeCredentials) {
          setTokenCredential(decodeCredentials);
          setTimeout(() => {
            formRef.current.click();
          }, 200);
        }
      } catch (error) {
        alert("something went wrong " + error);
      }
    }
  }, [])

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    let newData = { ...user_data, [name]: value };
    setUser(newData);
  };

  function onChangeCaptcha(value) {
    if (captcha.current.getValue()) {
      changeCaptchaValide(true);
    } else {
      changeCaptchaValide(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageAlert("");
    console.log(infoPathName)
    if (infoPathName === "User-Credential-Access") {
      if (tokenCredential) {
        const responseLoginWithToken = await verifyToken(tokenCredential);
        const userData = {
          auth: true,
          user_email: responseLoginWithToken.userName,
          user_name: (responseLoginWithToken.userName).split('@')[0],
          role: responseLoginWithToken.role,
          token: responseLoginWithToken.token,
          table: responseLoginWithToken.table,
          captcha: "",
        };
        signin(userData);
        changeCaptchaValide(true);
        changeUserValide(true);
        if (responseLoginWithToken.role.includes('Admin') || responseLoginWithToken.role.includes('User Enovate')) {
          navigate("/Admin");
          return null;
        } else {
          navigate("/");
          return null;
        }
      } else {
        alert("Incorrect user or password, please verify the data's form");
      }
    } else {
      if (user_data.user_password && user_data.user_name) {
        if (!e.target.checkValidity() || !captcha.current.getValue()) {
          changeCaptchaValide(false);
          changeUserValide(false);
        }
        else {
          if (captcha.current.getValue() && e.target.checkValidity()) {
            const responseLogin = await loginUser(user_data);
            if (responseLogin.token) {
              const userData = {
                auth: true,
                user_name: (user_data.user_name).split('@')[0],
                user_email: user_data.user_name,
                role: responseLogin.role,
                token: responseLogin.token,
                table: responseLogin.table,
                captcha: captcha.current?.getValue(),
              };
              signin(userData);
              changeCaptchaValide(true);
              changeUserValide(true);
              if (responseLogin.role.includes('Admin') || responseLogin.role.includes('User Enovate')) {
                navigate("/Admin");
                return null;
              } else {
                navigate("/Welcome");
                return null;
              }
            } else {
              setMessageAlert("Incorrect user or password, please verify the data's form");
            }
          }
        }
      } else {
        setMessageAlert("the username and password are required to enter");
      }
    }
  };

  return (
    <div className="container">
      <div className="background-blur"></div>
      <form
        onSubmit={handleSubmit}
        className="bg-login-form shandow-md rounded-xl lg:rounded-lg bg-opacity-30 w-96 h-full pt-8 pb-8 px-8"
      >
        <div className="mb-4">
          <h1 style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '60px', fontFamily: 'Inter, sans-serif' }} className=" text-center justify-center  text-white mt-[124px]">UPLOAD FILES</h1>
          <h2 style={{ fontWeight: '500', fontSize: '20px', lineHeight: '20px', fontFamily: 'Inter, sans-serif' }} className="text-nc-white  text-center justify-center  mt-[80px]">
            Transforming Oil & Gas through Digital innovation
          </h2>
        </div>
        <div className="mb-4 mt-[40px]">
          <label style={{ fontSize: '15px', lineHeight: '20px', fontFamily: 'Inter, sans-serif' }} htmlFor="text" className="text-nc-white">
            User name
          </label>
          <input
            type="email"
            name="user_name"
            id="user_name"
            value={user_data.user_name}
            onChange={handleInputChange}
            className="shadow appearance-none border-white border-[1px] border-opacity-[0.4] border-white rounded w-full py-2 px-3 text-nc-gray leading-tight focus:outline-none pr-10 bg-[#ffffff00]"
            style={{ color: 'text-nc-gray' }}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" style={{ fontSize: '15px', lineHeight: '20px', fontFamily: 'Inter, sans-serif' }} className="block text-nc-white">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="user_password"
              id="user_password"
              value={user_data.user_password}
              onChange={handleInputChange}
              className="shadow appearance-none border-white border-[1px] border-opacity-[0.4] border-white rounded w-full py-2 px-3 text-nc-gray leading-tight focus:outline-none pr-10 bg-[#ffffff00]"
              style={{ color: 'text-nc-gray' }}
            />
            <span
              className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <Tooltip title={`Hide password`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                </Tooltip>
              ) : (
                <Tooltip title={`Show password`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                </Tooltip>
              )}
            </span>
          </div>
        </div>
        <div className="recaptcha recaptcha-container">
          <ReCAPTCHA
            ref={captcha}
            sitekey="6Lc7K_clAAAAACgQF-Jus1GOgc63xbEU-cSUaA0Z"
            onChange={onChangeCaptcha}
            hl="en"
          />
        </div>
        {captchaValide === false && (
          <div className="my-3"><Alert message="Please check the captcha validation" type="error" showIcon /></div>
        )}
        {messageAlert.trim() !== "" &&
          <div className="my-3"><Alert message={messageAlert} type="error" showIcon /></div>
        }

        <div className="m-4 text-white flex justify-center">
          <button className="btn-1" ref={formRef}>
            <span className="text-xs">
              Submit
            </span>
          </button>
        </div>
        <label htmlFor="" className="text-white text-xs font-sans">Do not you remember your password? <NavLink to="/forgot-password" className="font-bold text-[#7209AF] hover:text-pc-blue font-sans">Recover</NavLink></label>
        <br />
        <label htmlFor="" className="text-white text-xs font-sans">Do not you have account? <NavLink to="/add-account" className="font-bold text-[#7209AF] hover:text-pc-blue font-sans">Request</NavLink></label>

        <div className="w-full h-20 text-center justify-normal items-center flex flex-col mt-[120px]">
          <img src={Logo} alt="" className="w-[230px] items-center "></img>
        </div>
      </form>
    </div>
  );
};
