import React, { useContext, useState } from 'react'
import { AuthContext } from '../Auth/context/Auth/AuthContext'
import { useQuery } from 'react-query';
import { userClients, userClientsId, userClientsProyects, userDataClients, userDataInfo, userProyects, fetchClientsPlatforms } from '../services/api/userData';

export const useHome = () => {
    const { User } = useContext(AuthContext);
    const [idDataClient, setIdDataClient] = useState("");
    const [idClient, setIdClient] = useState("");
    const [clients, setClients] = useState([]);
    const [proyects, setProyects] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [option, setOption] = useState();

    const { data: queryUserDataInfo, refetch: refetchUserDataInfo } = useQuery({
        queryKey: ['userDataInfo'],
        queryFn: () => userDataInfo(User.token, User.table, User.user_email),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            setIdDataClient(data?.user_clientId);
            if (User.role === "Simple User") {
                setTimeout(() => {
                    refetchUserClientsId();
                }, 500);
            }
            else {
                refetchUserClients();
            }
        },
        onError: () => { }
    });
    const { data: queryUserClients, refetch: refetchUserClients } = useQuery({
        queryKey: ['userClients'],
        queryFn: () => userClients(User.token),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            const clients = queryUserDataInfo?.user_clientId;
            const filteredClients = data?.filter(client =>
                clients.includes(client.idclient)
            );
            setClients(filteredClients)
        },
        onError: () => { }
    });
    const { data: queryUserClientsId, refetch: refetchUserClientsId } = useQuery({
        queryKey: ['userClientsId'],
        queryFn: () => userClientsId(User.token, idDataClient),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            setClients(data);
        },
        onError: () => { }
    });
    const { data: queryUserDataClients, refetch: refetchUserDataClients } = useQuery({
        queryKey: ['userDataClients'],
        queryFn: () => userDataClients(User.token, option),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            setIdClient(data.idclient);
            setTimeout(() => {
                if (data.idclient) {
                    refetchUserClientsProyects();
                } else {
                    refetchUserProyects();
                }
            }, 500);
        },
        onError: () => { }
    });
    const { data: qeuryUserClientsProyects, refetch: refetchUserClientsProyects } = useQuery({
        queryKey: ['userClientsProyects'],
        queryFn: () => userClientsProyects(User.token, idClient),
        enabled: false,
        retry: 1,
        onSuccess: (data) => {
            const clientsProyects = queryUserDataInfo?.proyects;
            const defaultDataOptionsProyects = data?.map((item) => {
                return item;
            });
            const filteredProjects = defaultDataOptionsProyects.filter(project =>
                clientsProyects.includes(project.proyect)
            );
            setProyects(filteredProjects);
            refectClientsPlatforms()
        },
        onError: () => { }
    });
    const { data: queryUserProyects, refetch: refetchUserProyects } = useQuery({
        queryKey: ['userProyects'],
        queryFn: () => userProyects(User.token),
        enabled: false,
        retry: 2,
        onSuccess: (data) => {
            const defaultDataOptionsProyects = data.map((item) => {
                return item.proyect;
            });
            const OptionsProyects = defaultDataOptionsProyects.filter(
                (valor, indice, self) => {
                    return self.indexOf(valor) === indice;
                }
            );
            setProyects(OptionsProyects);
        },
        onError: () => { }
    });


    const { data: listClientPlatforms, isLoading: loadClientPlatforms, isError: ErrorClientPlatforms, refetch: refectClientsPlatforms } = useQuery(
        ['listClientPlatforms', User.token, idClient],
        () => fetchClientsPlatforms(User.token, idClient),
        {
            enabled: false,
            retry: 1,
            onError: () => {

            },
            onSuccess: (data) => {
                const clientsPlatforms = queryUserDataInfo?.platforms;
                const defaultDataOptionsPlatforms = data?.map((item) => {
                    return item;
                });
                const filteredPlatforms = defaultDataOptionsPlatforms.filter(platform =>
                    clientsPlatforms.includes(platform.platform)
                );
                setPlatforms(filteredPlatforms);
            }
        }
    );


    return {
        queryUserDataInfo,
        queryUserClients,
        queryUserClientsId,
        queryUserDataClients,
        qeuryUserClientsProyects,
        queryUserProyects,
        refetchUserDataInfo,
        refetchUserClients,
        refetchUserClientsId,
        refetchUserDataClients,
        refetchUserClientsProyects,
        refetchUserProyects,
        clients,
        setClients,
        idDataClient,
        setIdDataClient,
        option,
        setOption,
        idClient,
        setIdClient,
        proyects,
        setProyects,
        platforms,
        setPlatforms
    }
}
