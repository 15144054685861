import React from 'react'
import { useFilter } from "../context/Filter/filterState";
import { useLocation } from 'react-router-dom';  // Asegúrate de importar useLocation si usas react-router

export default function Info() {
    const { dataFilter } = useFilter();
    const location = useLocation();  // Obtiene la ubicación actual
    const mode = 'dark'
    // Verifica si el path contiene la palabra 'plan'
    const showStreamingInfo = location.pathname.includes("/Plan");

    return (
        <div className="w-full h-full flex pt-3 gap-x-[0.8em] justify-center text-center items-center">
            <div className={`flex items-center justify-center px-4 py-2 rounded-full text-base ${mode === 'dark' ? 'bg-nc-gray-bg-cont bg-opacity-cont ' : 'bg-white'} `}>
                <p className={`text-xs ${mode === 'dark' ? 'text-white' : 'text-nc-gary-text'}`}>{dataFilter.clients}</p>
            </div>
            <div className={`flex items-center justify-center px-4 py-2 rounded-full text-base ${mode === 'dark' ? 'bg-nc-gray-bg-cont bg-opacity-cont ' : 'bg-white'} `}>
                <p className={`text-xs ${mode === 'dark' ? 'text-white' : 'text-nc-gary-text'}`}>{dataFilter.proyects}</p>
            </div>
            {showStreamingInfo && (
                <div className={`flex items-center justify-center px-4 py-2 rounded-full text-base ${mode === 'dark' ? 'bg-nc-gray-bg-cont bg-opacity-cont ' : 'bg-white'} `}>
                    <p className={`text-xs ${mode === 'dark' ? 'text-white' : 'text-nc-gary-text'}`}>{dataFilter.platforms}</p>
                </div>
            )}
        </div>
    )
}
