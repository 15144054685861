
import { useContext, useEffect, useState } from "react";
import { Collapse, Dropdown, initTE, Sidenav } from "tw-elements";
import Menus from "../../Components/layout/menus";
import Info from "../../Components/Info";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { deleteTimeBaseDrill, getListHistoricDrill, getListTimeBaseDrill, getTBD, uploadHistoricDrill, uploadTimeBaseDrill } from "../../services/api/fetchData";
import { useMutation, useQuery } from "react-query";
import { useFilter } from "../../context/Filter/filterState";
import { config } from '../../config/config';
import { AuthContext } from "../../Auth/context/Auth/AuthContext";
import { useHome } from "../../hooks/useHome";

const PORT_DB = config.API.PORT_DB;
export function Home() {
  const { getDataFilter, dataFilter } = useFilter();
  const { User } = useContext(AuthContext);
  const {
    queryUserDataInfo,
    refetchUserDataInfo,
    refetchUserDataClients,
    clients,
    setOption,
    proyects
  } = useHome();
  //constantes
  const [tabs, viewTabs] = useState(null);

  const [validateFilter, setValidateFilter] = useState(false);

  const [body, setBody] = useState({
    clients: "",
    proyects: "",
  });

  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [enableListHistoricDrill, setenableListHistoricDrill] = useState(false);
  const [enableListTimeBaseDrill, setenableListTimeBaseDrill] = useState(false);

  //Variables dinamicas
  useEffect(() => {
    initTE({ Collapse, Dropdown, Sidenav });
    refetchUserDataInfo();
  }, []);

  useEffect(()=>{
    if(queryUserDataInfo){
      if (sessionStorage.getItem('dataFilter')) {
        const data = JSON.parse(sessionStorage.getItem('dataFilter'))
        setOption(data.clients);
        setTimeout(() => {
          refetchUserDataClients();
        }, 300);

        setBody({
          clients: data.clients,
          proyects: data.proyects
        });
        setValidateFilter(true);
        setenableListHistoricDrill(true)
        setenableListTimeBaseDrill(true)
      } 
    }
  },[queryUserDataInfo])

  const handleChange = ({ target: { name, value } }) => {
    if(name === "clients"){
      setOption(value);
      setTimeout(() => {
        refetchUserDataClients();
      }, 300);
    }

    setBody({ ...body, [name]: value });
    setenableListHistoricDrill(false)
    setenableListTimeBaseDrill(false)
  };

  const handleSetFilter = () => {
    if ((clients.client !== "" || body.clients !== "") && body.proyects !== "") {
      setValidateFilter(true);
      getDataFilter({
        clients: body.clients.toLowerCase(),
        proyects: body.proyects.toLowerCase()
      });
      setenableListHistoricDrill(true);
      setenableListTimeBaseDrill(true)
      message.success("Filter obtained succesfuly, please continue with your browsing");
    }
  }

  const uploadHistoric = useMutation({
    mutationFn: uploadHistoricDrill,
    onSuccess: () => {
      setFileList1([]);
      message.success('upload successfully.');
    }
  })

  const uploadTBD = useMutation({
    mutationFn: uploadTimeBaseDrill,
    onSuccess: () => {
      setFileList2([]);
      message.success('upload successfully.');
    }
  })

  const deleteTBD = useMutation({
    mutationFn: deleteTimeBaseDrill,
    onSuccess: () => {
      message.success('deleted successfully.');
    }
  })

  const handleUploadHistoric = () => {
    const formData = new FormData();
    fileList1.forEach((file) => {
      formData.append('upload', file);
    });
    setUploading1(true);
    uploadHistoric.mutate({ file: formData, client: dataFilter.clients, proyect: dataFilter.proyects });
  };

  const handleUploadTimeBaseDrill = () => {
    const formData2 = new FormData();
    fileList2.forEach((file) => {
      formData2.append('upload', file);
    });
    setUploading2(true);
    uploadTBD.mutate({ file: formData2, client: dataFilter.clients, proyect: dataFilter.proyects });
  };

  const handeleDeleteTimeBaseDrill = () => {
    setUploading3(true);
    deleteTBD.mutate({ client: dataFilter.clients, proyect: dataFilter.proyects })
  }

  const props1 = {
    onRemove: (file) => {
      const index = fileList1.indexOf(file);
      const newFileList = fileList1.slice();
      newFileList.splice(index, 1);
      setFileList1(newFileList);
    },
    beforeUpload: (file) => {
      setFileList1([...fileList1, file]);
      return false;
    },
    fileList1,
  };

  const props2 = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      setFileList2([...fileList2, file]);
      return false;
    },
    fileList2,
  };

  var messageHistoric = "";
  var messageTimeBaseDrill = [];

  const { isLoading: isLoadinHistoric, error: err1, data: dataHistoricDrill } = useQuery({
    queryKey: ['historic'],
    queryFn: () => getListHistoricDrill(dataFilter.clients, dataFilter.proyects),
    enabled: enableListHistoricDrill
  })
  if (dataHistoricDrill !== undefined) {
    if (dataHistoricDrill.data !== null) {
      messageHistoric = dataHistoricDrill.data;

    }
  }

  const { isLoading: isLoadingTimeBaseDrill, error: err2, data: dataTimeBaseDrill } = useQuery({
    queryKey: ['timebasedrill'],
    queryFn: () => getListTimeBaseDrill(dataFilter.clients, dataFilter.proyects),
    enabled: enableListTimeBaseDrill
  })

  if (dataTimeBaseDrill !== undefined) {
    if (dataTimeBaseDrill.data !== null) {
      messageTimeBaseDrill = dataTimeBaseDrill.data;
    }
  }
  const handleRemove = (file) => {
    const updatedFileList = fileList1.filter((item) => item.uid !== file.uid);
    setFileList1(updatedFileList);
  };

  const renderFileListHist = () => {
    return fileList1.map((file) => {
      const color = file.status === 'done' ? 'text-green-600' : 'text-white';
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(file)}
          />
        </div>
      );
    });
  };

  const handleRemoveTBD = (file) => {
    const updatedFileList = fileList2.filter((item) => item.uid !== file.uid);
    setFileList2(updatedFileList);
  };
  const renderFileListTBD = () => {
    return fileList2.map((file) => {
      const color = file.status === 'done' ? 'text-green-6' : 'text-white';
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveTBD(file)}
          />
        </div>
      );
    });
  };

  return (
    <div className={`w-full h-screen  bg-nc-gray-bg `}>
      <Menus user={User.user_email} />
      <div className="w-full bg-nc-gray-2 text-center pb-40">
        <div className="flex items-center justify-center  w-full">
          <h1 className="font-sans font-bold text-4xl text-nc-white max-w-3xl lg:block m-10 text-center ">
            HISTORIC AND TIME BASE DRILL FILES
          </h1>
        </div>
        <div className="flex items-center justify-center">
          <div className="bg-nc-gray p-2  m-1 text-center w-[450px] rounded">
            <Info />
          </div>
        </div>
        <div className="flow-root font-sans bg-no-repeat bg-center bg-cover justify-center w-full my-20 h-3/5  lg:flex ">
          <div className="w-full flex items-center justify-center lg:items-end lg:justify-center lg:w-1/3 max-h-full h-[400px]">
            <form className="bg-nc-gray shandow-md rounded-xl lg:rounded-lg bg-opacity-80 w-9/12 h-full pt-8 pb-8 px-8 text-center justify-center">
              <h1 className="text-nc-white font-bold text-2xl">Client</h1>
              {clients && (
                <select
                  name="clients"
                  id="clients"
                  value={body.clients}
                  onChange={handleChange}
                  className="shadow appearance-none border-2 border-pc-purple rounded w-full py-2 px-3 text-nc-gray leading-tight focus:outline-none mb-4"
                  placeholder="Set a client"
                  required
                >
                  <option value="-1">Set a client</option>
                  <option key={clients.idclient} value={clients.client}>
                    {clients.client}
                  </option>
                </select>
              )}
              <h1 className="text-nc-white font-bold text-2xl">Proyects</h1>
              {proyects.length > 0 && body.clients !== "-1" &&                
                <select
                  name="proyects"
                  id="proyects"
                  value={body.proyects}
                  onChange={handleChange}
                  className="shadow appearance-none border-2 border-pc-purple rounded w-full py-2 px-3 text-nc-gray leading-tight focus:outline-none mb-4"
                  placeholder="Set a proyect"
                  required
                >
                  <option value={-1}>Set a proyect</option>
                  {proyects.map((option) => (
                    <option key={option.idproyect} value={option.proyect}>
                      {option.proyect}
                    </option>
                  ))}
                </select>
              }
              <Button className="btn-1 w-[130px] m-2" onClick={handleSetFilter}>Go</Button>
            </form>
          </div>
          {validateFilter && (
            <div className="w-full flex items-center justify-center lg:items-end lg:justify-center lg:w-1/3 max-h-full ">
              <div className="bg-nc-gray shandow-md rounded-xl lg:rounded-lg bg-opacity-80 w-9/12 h-full pt-8 pb-8 px-8 text-center justify-center">
                <div className="flex justify-center items-center h-10">
                  <h1 className="text-nc-white font-bold text-2xl">Historic Drill</h1>
                </div>
                <div className="border border-pc-purple rounded h-2/3 m-2">
                  <p className="text-nc-white">{messageHistoric}</p>

                </div>

                <div className=" flex justify-center gap-5">
                  <Upload {...props1} showUploadList={false} multiple={false}>
                    <Button className="btn-1" icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  <Button
                    type="primary"
                    onClick={handleUploadHistoric}
                    disabled={fileList1.length === 0}
                    loading={uploading1}

                    className={fileList1.length === 0?'hidden':"btn-1 w-96"}
                  >
                    {uploading1 ? 'Uploading' : 'Start Upload'}
                  </Button>
                </div>
                <div>
                  {renderFileListHist()}
                </div>
              </div>
            </div>
          )}
          {validateFilter && (

            <div className="w-full flex items-center justify-center lg:items-end lg:justify-center lg:w-1/3 max-h-full ">
              <div className="bg-nc-gray shandow-md rounded-xl lg:rounded-lg bg-opacity-80 w-9/12 h-full pt-8 pb-8 px-8 text-center justify-center">
                <div className="flex justify-center items-center h-10">
                  <h1 className="text-nc-white font-bold text-2xl">Time Base Drill</h1>
                </div>
                <div className="border border-pc-purple rounded h-2/3 m-2">
                  <ul>
                    {messageTimeBaseDrill.map((elemento, index) => (
                      <li key={index}><label htmlFor="" className="text-pc-purple text-xl">- </label><label htmlFor="" className="text-nc-white">{elemento}</label></li>
                    ))}
                  </ul>
                </div>
                <div className="flex justify-center gap-5">
                  <Upload {...props2} showUploadList={false} >
                    <Button className="btn-1" icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  <Button
                    type="primary"
                    onClick={handleUploadTimeBaseDrill}
                    disabled={fileList2.length === 0}
                    loading={uploading2}

                    className={fileList2.length === 0?'hidden':"btn-1"}
                  >
                    {uploading2 ? 'Uploading' : 'Start Upload'}
                  </Button>
                </div>
                <div>
                  <Button loading={uploading3} className="btn-1 w-[130px] m-2" onClick={handeleDeleteTimeBaseDrill} >{uploading3 ? 'Uploading' : 'Remove'}</Button>
                </div>
                <div>
                  {renderFileListTBD()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
