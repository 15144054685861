import { config } from "../../config/config";

const PORT_DB = config.API.PORT_DB;

export const userDataInfo = async (token, table, email) => {
  try {
    const response = await fetch(`${PORT_DB}/Username/${table}/${email}`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const userClients = async (token) => {
  try {
    const response = await fetch(`${PORT_DB}/Clients`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const userClientsId = async (token, idDataClient) => {
  try {
    const response = await fetch(`${PORT_DB}/Clients/${idDataClient}`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const userDataClients = async (token, option) => {
  try {
    const response = await fetch(`${PORT_DB}/DataClients/Clients/${option}`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const userClientsProyects = async (token, idclient) => {
  try {
    const response = await fetch(`${PORT_DB}/Clients/${idclient}/Proyects`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const userProyects = async (token) => {
  try {
    const response = await fetch(`${PORT_DB}/Proyects`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchClientsPlatforms = async (token, idClient) => {
  try {
    const response = await fetch(`${PORT_DB}/Clients/${idClient}/Platforms`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Se ha generado un error en el cargue de cleintes");
  }
};
