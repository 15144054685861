import {QueryClient, QueryClientProvider} from 'react-query'

import { AppRoutes } from "./routes/AppRoutes";
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <AppRoutes></AppRoutes>
    </>
  );
}

export default App;
