import React from "react";
import Logo from "../assets/image/Logo.png";

export const Loading = () => {
  return (
    <div className="h-screen w-screen bg-nc-gray-2">
        <div className="text-center justify-center items-center h-screen flex flex-col">
            <div className="flex text-center justify-center items-center">
                <img
                    src={Logo}
                    className="w-20 h-20 m-2 animate-spin" 
                    alt=""
                    loading="loading"
                />
            </div>
            <div>
                <h1 className="font-sans m-2 text-4xl bg-gradient-to-r from-pc-purple to-pc-aqua-2 text-transparent bg-clip-text animate-bounce ">LOADING</h1>
            </div>
        </div>
    </div>
  );
};
