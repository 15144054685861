

import React from 'react'
import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from './AuthContext';

export function AuthProvider({ children }) {
    const {
      Auth,
      User,
      role,
      getRole,
      signin,
      logout
    } = useAuth();

  return (
    <AuthContext.Provider value={
        {
          Auth,
          User,
          role,
          getRole,
          signin,
          logout
        }
    }>
      {children}
    </AuthContext.Provider>
  );
}
