import { config } from "../../config/config";

const BASE_URL = config.API.BASE_URL;
const TOKEN_FILTER = config.AUTH.TOKEN_FILTER;
const TOKEN_SCENARIO = config.AUTH.TOKEN_SCENARIO;

export const getListHistoricDrill = async (dataclient, dataproyect) => {
  const client = dataclient;
  const proyect = dataproyect;
  const response = await fetch(
    `${BASE_URL}/listhistoricdrill/${client}/${proyect}`,
    {
      method: "GET",
      headers: {
        Authorization: TOKEN_FILTER,
      },
    }
  );
  return response.json();
};

export const getListTimeBaseDrill = async (dataclient, dataproyect) => {
  const client = dataclient;
  const proyect = dataproyect;
  const response = await fetch(
    `${BASE_URL}/listtimebaseddrill/${client}/${proyect}`,
    {
      method: "GET",
      headers: {
        Authorization: TOKEN_FILTER,
      },
    }
  );
  return response.json();
};

export const uploadHistoricDrill = async (dataHistoric) => {
  const client = dataHistoric.client;
  const proyect = dataHistoric.proyect;
  const response = await fetch(
    `${BASE_URL}/historicdrill/${client}/${proyect}`,
    {
      method: "POST",
      headers: {
        Authorization: TOKEN_FILTER,
      },
      body: dataHistoric.file,
    }
  );
  return response.json();
};

export const uploadTimeBaseDrill = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(
    `${BASE_URL}/timebaseddrill/${client}/${proyect}`,
    {
      method: "POST",
      headers: {
        Authorization: TOKEN_FILTER,
      },
      body: timebasedrill.file,
    }
  );
  return response.json();
};

export const deleteTimeBaseDrill = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(
    `${BASE_URL}/timebaseddrill/${client}/${proyect}`,
    {
      method: "DELETE",
      headers: {
        Authorization: TOKEN_FILTER,
      },
    }
  );
  return response.json();
};

export const getTBD = async (timebasedrill) => {
  const response = await fetch(`${BASE_URL}/listtimebaseddrill/exxon/norte`, {
    method: "GET",
    headers: {
      Authorization: TOKEN_FILTER,
    },
  });
  return response.json();
};

export const getHistoricDrill = async () => {
  const response = await fetch(`${BASE_URL}/historicdrill/exxon/norte`, {
    method: "GET",
    headers: {
      Authorization: TOKEN_FILTER,
    },
  });
  return response.json();
};
export const uploadplan = async (plan) => {
  try {
    const client = plan?.filter.clients.toLowerCase();
    const proyect = plan?.filter.proyects.toLowerCase();
    const stream = plan?.filter.platforms.toLowerCase();

    const response = await fetch(
      `${BASE_URL}/plan/${client}/${proyect}/${stream}`,
      {
        method: "POST",
        headers: {
          Authorization: TOKEN_SCENARIO,
        },
        body: plan.file,
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};
