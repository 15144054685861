import React from 'react'
import { AuthProvider } from '../Auth/context/Auth/AuthProvider';
import { FilterProvider } from '../context/Filter/filterState';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../Components/ProtectedRoute';
import { HomeAdmin } from '../pages/Home/HomeAdmin';
import { Home } from '../pages/Home/Home';
import { Login } from '../Auth/pages/Login';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Plan } from '../pages/Plan/Plan';

const queryClient = new QueryClient();
export const AppRoutes = () => {
  return (
    <div className="bg-slate-600 h-screen text-black">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FilterProvider>
            <Routes>
              <Route element={<ProtectedRoute isAllowed="External" />}>
                <Route path="/Welcome" element={<Home />} />
              </Route>
              <Route element={<ProtectedRoute isAllowed="Internal" />}>
                <Route path="/Admin" element={<HomeAdmin />} />
                <Route path="/Plan" element={<Plan />} />
              </Route>
              <Route path="/Login" element={<Login />} />
              <Route path="/User-Credential-Access/:access_credentials" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </FilterProvider>
        </AuthProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </div>
  );
}
