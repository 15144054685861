
import { useContext, useEffect, useState, useRef } from "react";
import { Collapse, Dropdown, initTE, Sidenav } from "tw-elements";
import Menus from "../../Components/layout/menus";
import Info from "../../Components/Info";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { deleteTimeBaseDrill, getListHistoricDrill, getListTimeBaseDrill, getTBD, uploadHistoricDrill, uploadTimeBaseDrill } from "../../services/api/fetchData";
import { useMutation, useQuery } from "react-query";
import { useFilter } from "../../context/Filter/filterState";
import { config } from '../../config/config';
import { AuthContext } from "../../Auth/context/Auth/AuthContext";
import { useHome } from "../../hooks/useHome";

const PORT_DB = config.API.PORT_DB;
export function HomeAdmin() {
  const { getDataFilter, dataFilter } = useFilter();
  const { User } = useContext(AuthContext);
  const {
    queryUserDataInfo,
    refetchUserDataInfo,
    refetchUserDataClients,
    clients,
    setOption,
    proyects
  } = useHome();
  const mode = 'dark'
  //constantes
  const containerTag = useRef(null);
  const generalRef = useRef(null);
  const historicRef = useRef(null);
  const tbdRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [tabs, viewTabs] = useState(null);

  const [validateFilter, setValidateFilter] = useState(false);
  const [body, setBody] = useState({
    clients: "",
    proyects: "",
  });

  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [enableListHistoricDrill, setenableListHistoricDrill] = useState(false);
  const [enableListTimeBaseDrill, setenableListTimeBaseDrill] = useState(false);

  //Variables dinamicas
  useEffect(() => {
    initTE({ Collapse, Dropdown, Sidenav });
    refetchUserDataInfo();
  }, []);

  useEffect(() => {
    if (queryUserDataInfo) {
      if (sessionStorage.getItem('dataFilter')) {
        const data = JSON.parse(sessionStorage.getItem('dataFilter'))
        setOption(data.clients);
        setTimeout(() => {
          refetchUserDataClients();
        }, 300);

        setBody({
          clients: data.clients,
          proyects: data.proyects
        });
        setValidateFilter(true);
        setenableListHistoricDrill(true)
        setenableListTimeBaseDrill(true)
      }
    }
  }, [queryUserDataInfo])
  useEffect(() => {
    const calculateMaxHeight = () => {
      const generalHeight = generalRef.current ? generalRef.current.scrollHeight : 0;
      const wellDesignHeight = historicRef.current ? historicRef.current.scrollHeight : 0;
      const wellProfileHeight = tbdRef.current ? tbdRef.current.scrollHeight : 0;

      // Obtener el máximo height de los tres componentes
      let calculatedMaxHeight = Math.max(generalHeight, wellDesignHeight, wellProfileHeight);

      // Ajustar el maxHeight basado en el tamaño de la ventana del navegador
      const screenHeight = window.innerHeight;
      const maxHeightAdjustment = 300; // Ajusta este valor según sea necesario
      calculatedMaxHeight = Math.min(calculatedMaxHeight, screenHeight - maxHeightAdjustment);

      setMaxHeight(calculatedMaxHeight);
    };

    calculateMaxHeight();
    window.addEventListener('resize', calculateMaxHeight);

    return () => window.removeEventListener('resize', calculateMaxHeight);
  }, []);


  const handleChange = ({ target: { name, value } }) => {
    if (name === "clients") {
      setOption(value);
      setTimeout(() => {
        refetchUserDataClients();
      }, 300);
    }
    setBody({ ...body, [name]: value });
    setenableListHistoricDrill(false)
    setenableListTimeBaseDrill(false)
  };

  const handleSetFilter = () => {
    if ((clients.client !== "" || body.clients !== "") && body.proyects !== "") {
      setValidateFilter(true);
      getDataFilter({
        clients: body.clients.toLowerCase(),
        proyects: body.proyects.toLowerCase()
      });
      setenableListHistoricDrill(true);
      setenableListTimeBaseDrill(true)
      message.success("Filter obtained succesfuly, please continue with your browsing");
    }
  }

  const uploadHistoric = useMutation({
    mutationFn: uploadHistoricDrill,
    onSuccess: () => {
      setFileList1([]);
      message.success('upload successfully.');
    }
  })

  const uploadTBD = useMutation({
    mutationFn: uploadTimeBaseDrill,
    onSuccess: () => {
      setFileList2([]);
      message.success('upload successfully.');
    }
  })

  const deleteTBD = useMutation({
    mutationFn: deleteTimeBaseDrill,
    onSuccess: () => {
      message.success('deleted successfully.');
    }
  })

  const handleUploadHistoric = () => {
    const formData = new FormData();
    fileList1.forEach((file) => {
      formData.append('upload', file);
    });
    setUploading1(true);
    uploadHistoric.mutate({ file: formData, client: dataFilter?.clients, proyect: dataFilter?.proyects });
  };

  const handleUploadTimeBaseDrill = () => {
    const formData2 = new FormData();
    fileList2.forEach((file) => {
      formData2.append('upload', file);
    });
    setUploading2(true);
    uploadTBD.mutate({ file: formData2, client: dataFilter?.clients, proyect: dataFilter?.proyects });
  };

  const handeleDeleteTimeBaseDrill = () => {
    setUploading3(true);
    deleteTBD.mutate({ client: dataFilter?.clients, proyect: dataFilter?.proyects })
  }

  const props1 = {
    onRemove: (file) => {
      const index = fileList1.indexOf(file);
      const newFileList = fileList1.slice();
      newFileList.splice(index, 1);
      setFileList1(newFileList);
    },
    beforeUpload: (file) => {
      setFileList1([...fileList1, file]);
      return false;
    },
    fileList1,
  };

  const props2 = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      setFileList2([...fileList2, file]);
      return false;
    },
    fileList2,
  };

  var messageHistoric = "";
  var messageTimeBaseDrill = [];

  const { isLoading: isLoadinHistoric, error: err1, data: dataHistoricDrill } = useQuery({
    queryKey: ['historic'],
    queryFn: () => getListHistoricDrill(dataFilter?.clients, dataFilter?.proyects),
    enabled: enableListHistoricDrill
  })
  if (dataHistoricDrill !== undefined) {
    if (dataHistoricDrill.data !== null) {
      messageHistoric = dataHistoricDrill.data;

    }
  }

  const { isLoading: isLoadingTimeBaseDrill, error: err2, data: dataTimeBaseDrill } = useQuery({
    queryKey: ['timebasedrill'],
    queryFn: () => getListTimeBaseDrill(dataFilter?.clients, dataFilter?.proyects),
    enabled: enableListTimeBaseDrill
  })

  if (dataTimeBaseDrill !== undefined) {
    if (dataTimeBaseDrill.data !== null) {
      messageTimeBaseDrill = dataTimeBaseDrill.data;
    }
  }
  const handleRemove = (file) => {
    const updatedFileList = fileList1.filter((item) => item.uid !== file.uid);
    setFileList1(updatedFileList);
  };

  const renderFileListHist = () => {
    return fileList1.map((file) => {
      const color = file.status === 'done' ? 'text-green-600' : 'text-white';
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(file)}
          />
        </div>
      );
    });
  };

  const handleRemoveTBD = (file) => {
    const updatedFileList = fileList2.filter((item) => item.uid !== file.uid);
    setFileList2(updatedFileList);
  };
  const renderFileListTBD = () => {
    return fileList2.map((file) => {
      const color = file.status === 'done' ? 'text-green-6' : 'text-white';
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveTBD(file)}
          />
        </div>
      );
    });
  };

  return (
    <div className={`w-full h-screen ${mode === 'light' ? 'bg-nc-gray-bg-light' : 'bg-nc-gray-bg'} bg-fd-home `}>
      <Menus user={User.user_email} />
      <div className="flex justify-center items-center h-full flex-col gap-y-16" ref={containerTag}>
        {validateFilter && (
          <div className="flex w-full justify-center text-center items-center ">
            <div className={`flex justify-center text-center items-center p-2 m-1 rounded w-[450px]`}>
              <Info />
            </div>
          </div>)}
        <>

          <div className={`${validateFilter ? 'grid grid-cols-3' : 'flex justify-center items-center'} `}>

            <div className="flex items-center justify-center mt-10 xl:mt-0">
              <div className={`p-2 text-center w-[450px] max-h-[620px] rounded`} style={{ height: maxHeight }}>
                <div className={`font-sans w-full h-full`}>
                  <div className="w-full flex items-center justify-center h-full">
                    <form ref={generalRef} style={{ position: 'relative' }} className={`${mode === 'light' ? 'bg-[white]' : 'bg-nc-gray'}  shandow-md rounded-xl lg:rounded-lg h-auto pt-8 pb-8 px-8`}>
                      <div className="flex lg:justify-between">
                        <h1 style={{ fontFamily: 'Inter, sans-serif' }} className={`${mode === 'light' ? 'text-black' : ' text-nc-white '}font-sans font-bold text-2xl  text-center justify-center max-w-2xl lg:block m-auto`}>
                          HISTORIC AND TIME BASE DRILL FILES
                        </h1>
                      </div>
                      <h1 className={`${mode == 'light' ? 'text-nc-gray' : 'text-nc-white'} text-start text-l`}>Client</h1>
                      <select
                        name="clients"
                        id="clients"
                        value={body.clients}
                        onChange={handleChange}
                        className={`${mode == 'light' ? 'text-nc-gray  border-[#00000033]' : 'text-white  border-[#ffffff6e] '} bg-[#ffffff00] shadow appearance-none border-[1px] rounded w-full py-2 px-3 leading-tight focus:outline-none mb-4`}
                        placeholder="Set a client"
                        required
                      >
                        <option className="text-nc-gray" value={-1}>Set a client</option>
                        {clients.map((option) => (
                          <option className="text-nc-gray" key={option.idclient} value={option.client}>
                            {option.client}
                          </option>
                        ))}
                      </select>
                      <h1 className={`${mode == 'light' ? 'text-nc-gray' : 'text-nc-white'} text-start text-l`}>Project</h1>
                      <select
                        name="proyects"
                        id="proyects"
                        value={body.proyects}
                        onChange={handleChange}
                        className={`${mode == 'light' ? 'text-nc-gray  border-[#00000033]' : 'text-white  border-[#ffffff6e] '} bg-[#ffffff00] shadow appearance-none border-[1px] rounded w-full py-2 px-3 leading-tight focus:outline-none mb-4`}
                        placeholder="Set a proyect"
                        required
                      >
                        <option className="text-nc-gray" value={-1}>Set a proyect</option>
                        {proyects?.map((option) => (
                          <option className="text-nc-gray" key={option.idproyect} value={option.proyect}>
                            {option.proyect}
                          </option>
                        ))}
                      </select>
                      <Button className="btn-1 mt-[35px]" onClick={handleSetFilter}>Go</Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {validateFilter && (

              <div className="flex items-center justify-center mt-10 xl:mt-0">
                <div className={`p-2 text-center w-[450px] max-h-[620px] rounded`}>
                  <div className={`font-sans w-full h-full`}>
                    <div className="w-full flex items-center justify-center h-full">
                      <div ref={historicRef} style={{ position: 'relative', height: maxHeight }} className={`${mode === 'light' ? 'bg-[white]' : 'bg-nc-gray'}  shandow-md rounded-xl lg:rounded-lg h-auto pt-8 pb-8 px-8`}>
                        <div className="flex lg:justify-between">
                          <h1 style={{ fontFamily: 'Inter, sans-serif' }} className={`${mode === 'light' ? 'text-black' : ' text-nc-white '}font-sans font-bold text-2xl  text-center justify-center max-w-2xl lg:block m-auto`}>
                            Historic Drill
                          </h1>
                        </div>
                        <div className="border border-pc-purple rounded h-2/3 m-2">
                          <p className="text-nc-white">{messageHistoric}</p>
                        </div>

                        <div className=" flex justify-center gap-5">
                          <Upload {...props1} showUploadList={false} multiple={false}>
                            <Button className="btn-1" icon={<UploadOutlined />}>Select File</Button>
                          </Upload>
                          <Button
                            type="primary"
                            onClick={handleUploadHistoric}
                            disabled={fileList1.length === 0}
                            loading={uploading1}

                            className={fileList1.length === 0 ? 'hidden' : "btn-1 w-96"}
                          >
                            {uploading1 ? 'Uploading' : 'Start Upload'}
                          </Button>
                        </div>
                        <div>
                          {renderFileListHist()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {validateFilter && (

              <div className="flex items-center justify-center mt-10 xl:mt-0">
                <div className={`p-2 text-center w-[450px] max-h-[620px] rounded`}>
                  <div className={`font-sans w-full h-full`}>
                    <div className="w-full flex items-center justify-center h-full">
                      <div ref={tbdRef} style={{ position: 'relative', height: maxHeight }} className={`${mode === 'light' ? 'bg-[white]' : 'bg-nc-gray'}  shandow-md rounded-xl lg:rounded-lg h-auto pt-8 pb-8 px-8`}>
                        <div className="flex lg:justify-between">
                          <h1 style={{ fontFamily: 'Inter, sans-serif' }} className={`${mode === 'light' ? 'text-black' : ' text-nc-white '}font-sans font-bold text-2xl  text-center justify-center max-w-2xl lg:block m-auto`}>
                            Time Base Drill
                          </h1>
                        </div>
                        <div className="border border-pc-purple rounded h-2/3 m-2">
                          <ul>
                            {messageTimeBaseDrill.map((elemento, index) => (
                              <li key={index}><label htmlFor="" className="text-pc-purple text-xl">- </label><label htmlFor="" className="text-nc-white">{elemento}</label></li>
                            ))}
                          </ul>
                        </div>
                        <div className="flex justify-center gap-5">
                          <Upload {...props2} showUploadList={false} >
                            <Button className="btn-1" icon={<UploadOutlined />}>Select File</Button>
                          </Upload>
                          <Button
                            type="primary"
                            onClick={handleUploadTimeBaseDrill}
                            disabled={fileList2.length === 0}
                            loading={uploading2}

                            className={fileList2.length === 0 ? 'hidden' : "btn-1"}
                          >
                            {uploading2 ? 'Uploading' : 'Start Upload'}
                          </Button>
                        </div>
                        <div>
                          <Button loading={uploading3} className="btn-1 w-[130px] m-2" onClick={handeleDeleteTimeBaseDrill} >{uploading3 ? 'Uploading' : 'Remove'}</Button>
                        </div>
                        <div>
                          {renderFileListTBD()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </div >
  );
}

