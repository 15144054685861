import{ createContext, useContext, useEffect, useState } from "react";

export const filterContext = createContext();

export const useFilter = () => {
  const context = useContext(filterContext);
  return context;
};

export function FilterProvider({children}){
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(()=>{
    const getfilters = JSON.parse(sessionStorage.getItem('dataFilter'));
    if (getfilters) {
        setDataFilter(getfilters);
      }
    },[])

  const getDataFilter = (filters) => {
    setDataFilter(filters);
    sessionStorage.setItem('dataFilter',JSON.stringify(filters))
  }

  return (
    <filterContext.Provider value={{ getDataFilter, dataFilter}}>
      {children}
    </filterContext.Provider>
  );
};


