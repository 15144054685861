import React, { useState, useEffect  } from 'react'
import Logo from "../../assets/image/logo-white.png";

export default function Footer(user_info) {
  const [formattedDate, setFormattedDate] = useState('');
  let user_email = user_info.user_info === undefined ? user_info.email : user_info.user_info

  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
      };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      setFormattedDate(formattedDate);
    }, 1000); // Actualiza la fecha cada segundo

    return () => {
      clearInterval(interval); // Limpia el intervalo al desmontar el componente
    };
  }, []);
  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  const enterFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    setIsFullscreen(false);
  };

  return (
    <div className='fixed
        bottom-0
        w-full
        z-[10000]'>
      {/* <!-- Footer container --> */}
      <footer
        className="bg-nc-gray text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left lg:h-14">
        <div
          className="flex items-center justify-center border-b-2 border-neutral-200 dark:border-neutral-500 lg:justify-between lg:h-14">
          <div className="ml-3 mr-12 hidden lg:block">
            <span>{user_email}</span>
          </div>
          <div className="hidden lg:block absolute w-full h-14 ">
            <img
              src={Logo}
              className="w-28 m-auto justify-end py-1"
              alt=""
              loading="lazy" />
          </div>
          <div className="flex justify-center">
            <span>{formattedDate}</span>
            <a href="#!" className="mr-6 text-nc-white" onClick={handleFullscreenToggle}>
              <svg stroke="white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                <path d="M 6 6 L 6 18 A 1.0001 1.0001 0 1 0 8 18 L 8 9.4140625 L 20.292969 21.707031 A 1.0001 1.0001 0 1 0 21.707031 20.292969 L 9.4140625 8 L 18 8 A 1.0001 1.0001 0 1 0 18 6 L 6 6 z M 32 6 A 1.0001 1.0001 0 0 0 31.929688 7.9980469 A 1.0001 1.0001 0 0 0 32 8 L 40.496094 8 L 28.291016 20.294922 A 1.0001 1.0001 0 0 0 28.912109 22.011719 A 1.0001 1.0001 0 0 0 29.011719 22.013672 A 1.0001 1.0001 0 0 0 29.708984 21.705078 L 42 9.3242188 L 42 18 A 1.0001 1.0001 0 1 0 44 18 L 44 6 L 32 6 z M 20.984375 27.990234 A 1.0001 1.0001 0 0 0 20.294922 28.291016 L 8 40.496094 L 8 32 A 1.0001 1.0001 0 1 0 6 32 L 6 44 L 18 44 A 1.0001 1.0001 0 0 0 18.099609 43.996094 A 1.0001 1.0001 0 0 0 18 42 L 9.3242188 42 L 21.705078 29.708984 A 1.0001 1.0001 0 0 0 20.984375 27.990234 z M 28.990234 27.990234 A 1.0001 1.0001 0 0 0 28.292969 29.707031 L 40.585938 42 L 32 42 A 1.0001 1.0001 0 0 0 31.929688 43.998047 A 1.0001 1.0001 0 0 0 32 44 L 44 44 L 44 32 A 1.0001 1.0001 0 1 0 42 32 L 42 40.585938 L 29.707031 28.292969 A 1.0001 1.0001 0 0 0 28.990234 27.990234 z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}
